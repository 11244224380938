<template>
  <main>
    <div v-if="userAccount && offering">
      <div class="form-outline mb-4 container-fluid">
        <h6>Investment Confirmation</h6>
        Congratulations, your investment has been submitted. It may take a few minutes for your investment to show up on
        your investments page and a couple of days for your investment to be approved.
      </div>
      <div v-if="errorOffering">{{ errorOffering }}</div>
      <div class="row text-center">
        <div class="col">
          <router-link :to="{ name: 'Investments', params: { id: offering.id } }" class="btn btn-primary">
            View Your Investments
          </router-link>
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="userAccount" />
</template>

<script>
import getUser from "@/composables/getUser";
import Footer from "@/components/navigation/Footer.vue";
import getDocument from "@/composables/getDocument";

export default {
  props: ["id", "draftId"],
  components: { Footer },
  setup(props) {
    const { user } = getUser();
    const { document: userAccount } = getDocument("users", user.value.uid);
    const { error: errorOffering, document: offering } = getDocument(
      "offerings",
      props.id
    );

    return {
      userAccount,
      errorOffering,
      offering
    };
  }
};
</script>

<style></style>